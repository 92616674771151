export const questions =[
    {
        id: 1,
        title: "Can I learn ReactJs?",
        answer: "Yes, you can learn ReactJs at Youtube"
    },

    {
        id: 2,
        title: "How can i get started with SocialX?",
        answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis minima fugit repudiandae alias corrupti ullam nisi officia, vero eos autem iste facilis aspernatur quibusdam quae voluptatum. Facilis sit vitae consequatur!"
    },

    {
        id: 1,
        title: "Is there a refund policy?",
        answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis minima fugit repudiandae alias corrupti ullam nisi officia, vero eos autem iste facilis aspernatur quibusdam quae voluptatum. Facilis sit vitae consequatur!"
    },
   
]